<template>
	<div>
		404
	</div>
</template>

<script>
export default {
	data() {
		return { }
	},
	mounted() { },
	components: { },
	methods: { }
}
</script>

<style>

</style>